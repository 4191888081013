import React, {useState, useEffect} from 'react'
import './RightBar.css'
import {  useNotification } from '@web3uikit/core'
import { Input } from '@web3uikit/core'
import { Search } from '@web3uikit/icons'
import Web3Modal from 'web3modal'
import { MyraContractAddress, TagContractAddress, UserContractAddress } from '../config'
import TagAbi from '../abi/TagBusiness.json'
import UserAbi from '../abi/UserBusiness.json'
import MyraAbi from '../abi/MyraToken.json'
import { ethers, BigNumber } from 'ethers'

function RightBar() {
    const [uploading, setUploading] = useState(false);
    const notification = useNotification();
    const [tags, setTags] = useState([]);
    const [accountType, setAccountType] = useState('');
    const [user, setUser] = useState('');
    const [tagText, setTagText] = useState('')
    const [day, setDay] = useState(0)
    const [finishDate, setFinishDate] = useState(0)
    const activeAccount = JSON.parse(localStorage.getItem('activeAccount'));
    const [amount, setAmount] = useState(0)
    
    useEffect( ()=>{
         getUserDetail();
         getTags();
    },[]);

    const handleDayChange = (event)=> {
        setDay(event.target.value)
        if(Number(event.target.value) === 1){
            const startdate = Math.round(new Date().getTime()/1000);
            
            setFinishDate(startdate + (24 * 3600))
            setAmount(3750000)
        }else if(Number(event.target.value) ===2){
            const startdate = Math.round(new Date().getTime()/1000);
            
            setFinishDate(startdate + (24 * 3600))
            setAmount(5625000)
        }else if(Number(event.target.value) ===3){
            const startdate = Math.round(new Date().getTime()/1000);
            setFinishDate(startdate + (24 * 3600))
            setAmount(7500000)
        }
    }

    async function getUserDetail(){
        const web3Modal = new Web3Modal();
        const connection = await web3Modal.connect();
        const provider = new ethers.providers.Web3Provider(connection);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(UserContractAddress, UserAbi.abi,signer);
        const data = await contract.getAccountType(activeAccount);
        setAccountType(data);
        
    }

    async function addTag(){
        
        if(tagText.length < 3){
            notification({
                type:'warning',
                message:'^Minimum 3 characters',
                title:'Tag field required',
                position:'topR'
            });
            return;
        }
        setUploading(true);
        
        const web3Modal = new Web3Modal();
        const connection = await web3Modal.connect();
        const provider = new ethers.providers.Web3Provider(connection);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(TagContractAddress, TagAbi.abi,signer);
        const contractMyra = new ethers.Contract(MyraContractAddress, MyraAbi.abi, signer);
        const myraDecimal = await contractMyra.decimals();
        const tokenAmountInEther = ethers.utils.parseUnits(amount.toString(), myraDecimal);
        
        try{
            const dataForTrans = await contractMyra.transfer("0xdb05439d6211e26607Aee66CB96f14f0330669e7", tokenAmountInEther);
            await dataForTrans.wait();
            const last = dataForTrans.hash
            
            if(last){

                notification({
                    type:'info',
                    title:'Wait for processing and approve transaction for verfying tag!',
                    position:'topR',
                });
                try{
                    const transaction = await contract.addTag(tagText, day, finishDate, amount);
                    await transaction.wait();
                    notification({
                        type:'success',
                        title:'Tag added Successfully',
                        position:'topR',
                    });
                }catch(e){
                    notification({
                        type:'error',
                        title:'Tag Added Not Successfully',
                        position:'topR',
                    });
                }
             
               
            }else{
                notification({
                    type:'error',
                    title:'Transfer is not success!',
                    position:'topR',
                });
            }
            //console.log('wait : ',dataWait)
            
          
        }catch(e){
            notification({
                type:'error',
                title:'Transfer is not success!',
                position:'topR',
            });
        }
       
    }

    async function getTags(){
        const web3Modal = new Web3Modal();
        const connection = await web3Modal.connect();
        const provider = new ethers.providers.Web3Provider(connection);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(TagContractAddress, TagAbi.abi,signer);
        const data = await contract.getAllTags();

        const result = await Promise.all(data.map(async tag=>{
            const startedDate = tag.startDate;
            const date = new Date(startedDate * 1000);
            const tagStartedDate = date.toLocaleDateString("fr-CH");

            const finishedDate = tag.startDate;
            const dateFinish = new Date(finishedDate * 1000);
            const tagFinishedDate = dateFinish.toLocaleDateString("fr-CH");

            let item = {
                tagId:tag.tagId,
                tagOwer : tag.TagOwner,
                tagText : tag.tagText,
                isDeleted : tag.isDeleted,
                startDate : tagStartedDate,
                finishDate : tagFinishedDate,
                day : tag.day
            };
            setUser(tag.userAddress);
            return item;
        }));
        setTags(result.reverse());
    }

  return (
    <>
    
        <div className='rightBarContent'>
            {/* <Input label='Search Twitter' name='Search Twitter' prefixIcon={<Search/>} labelBgColor="#141d"></Input> */}
            <div className='trends'>
                <>
                    {
                        accountType === "Blue_Type" || accountType === "Golden_Type" ? 
                        <div className='tags-info'>
                            <input className='text-for-tag' type='text'onChange={(e)=> setTagText(e.target.value)}/>
                            
                            <select className='drop-for-day' value={day} onChange={handleDayChange}>
                                <option value={0}>How Long?</option>
                                <option value={1}>1 Day</option>
                                <option value={2}>2 Day</option>
                                <option value={3}>3 Day</option>
                            </select>
                            <div className='contained' color='success' onClick={addTag}>Add</div>
                        </div> :
                        <div>

                        </div>
                    }
                </>
                <hr/>
                Tags
                {
                    tags.map((tag)=>(
                        
                        

                        
                            <div className='feed-tag' key={tag.tagId}>
                                {/* <Avatar isRounded image={userImage}/> */}
                               
                                <div className='complete-tag' key={tag.tagId}>
                                    <div className='tag-text' style={{color:'white'}}>
                                       <a className='linkoftag' href={"/blabintag/"+ tag.tagId} >{"#" + tag.tagText}</a> 
                                    </div>
                                   
                                     <hr/>
                                </div>
                            </div>
                         
                    ))
                }
            </div>
        </div>
                        
    </>
  )
}

export default RightBar;
