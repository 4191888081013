import React, { useEffect, useState, useRef } from "react";
import { Avatar, Loading, useNotification } from "@web3uikit/core";
import {
  UserContractAddress,
  CommentContractAddress,
  FollowingContractAddress,
  Web3StorageApi,
} from "../config";
import "../abi/BlabBusiness.json";
import "./Comment.css";
import { Image } from "@web3uikit/icons";
import Web3Modal, { local } from "web3modal";
import { ethers } from "ethers";
import CommentAbi from "../abi/CommentBusiness.json";
import UserAbi from "../abi/UserBusiness.json";
import FollowAbi from "../abi/FollowingBusiness.json";
import { Web3Storage } from "web3.storage";
import blue from "../images/blue.png";
import gold from "../images/gold.png";

function Comment({ blab }) {
  const [comments, setComments] = useState([]);
  const UserImage = JSON.parse(localStorage.getItem("userImage"));
  const inputFile = useRef(null);
  const [commentText, setCommentText] = useState("");
  const [selectedImage, setSelectedImage] = useState();
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [accountType, setAccountType] = useState("");
  const [userAddress, setUserAddress] = useState(0);
  const activeAccount = JSON.parse(localStorage.getItem("activeAccount"));
  const notification = useNotification();
  let ipfsUploadedUrl = "";
  useEffect(() => {
    showCommentsByBlabId();
  }, []);

  async function storeFile() {
    const client = new Web3Storage({ token: Web3StorageApi });
    const rootCid = await client.put(selectedFile);
    ipfsUploadedUrl = `https://${rootCid}.ipfs.dweb.link/${selectedFile[0].name}`;
  }

  const onImageClick = () => {
    inputFile.current.click();
  };

  const changeHandler = (event) => {
    const imgFile = event.target.files[0];
    setSelectedImage(URL.createObjectURL(imgFile));
    setSelectedFile(event.target.files);
  };

  async function showCommentsByBlabId() {
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      CommentContractAddress,
      CommentAbi.abi,
      signer
    );
    const data = await contract.getCommentByBlabId(blab);

    const result = await Promise.all(
      data.map(async (comment) => {
        const unitTime = comment.timestamp;
        const date = new Date(unitTime * 1000);
        const commentDate = date.toLocaleDateString("fr-CH");
        const userContract = new ethers.Contract(
          UserContractAddress,
          UserAbi.abi,
          signer
        );
        const getUserDetail = await userContract.getUser(comment.userAddress);
        const getAccountType = await userContract.getAccountType(
          comment.userAddress
        );

        let item = {
          commentId: comment.commentId,
          commentText: comment.commentText,
          commentImg: comment.commentImg,
          blabId: comment.blabId,
          userName: getUserDetail["name"],
          userImage: getUserDetail["profileImg"],
          userAd: getUserDetail["userAddress"],
          isDeleted: comment.isDeleted,
          date: commentDate,
        };
        setAccountType(getAccountType);

        setUserAddress(comment.userAd);
        return item;
      })
    );
    setComments(result.reverse());
    
  }

  async function addComment() {
    if (commentText.trim().length < 2) {
      notification({
        type: "warning",
        message: "^Minimum 2 characters",
        title: "Comment field required",
        position: "topR",
      });
      return;
    }
    setUploading(true);
    if (selectedImage) {
      await storeFile();
    }
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      CommentContractAddress,
      CommentAbi.abi,
      signer
    );

    try {
      const transaction = await contract.addComment(
        blab,
        commentText,
        ipfsUploadedUrl
      );
      await transaction.wait();
      notification({
        type: "success",
        title: "Comment Added Successfully",
        position: "topR",
      });
      setSelectedImage(null);
      setCommentText("");
      setSelectedFile(null);
      setUploading(false);
    } catch (error) {
      notification({
        type: "error",
        title: "Transaction Error",
        message: error.message,
        position: "topR",
      });
      setUploading(false);
    }
  }

  async function follow(userAddress1) {
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      FollowingContractAddress,
      FollowAbi.abi,
      signer
    );
    const data = await contract.follow(userAddress1);
  }

  return (
    <div className="mainContentComment">
      <div className="profileComment">
        <div className="commentSection">
          <Avatar isRounded image={UserImage} theme="image" size={60} />
          <textarea
            value={commentText}
            className="textArea"
            placeholder="Whats going on?"
            name="TweetTxtArea"
            onChange={(e) => setCommentText(e.target.value)}
          ></textarea>
        </div>
        <div className="tweetSection">
          <div className="imgDiv" onClick={onImageClick}>
            <input
              type="file"
              ref={inputFile}
              onChange={changeHandler}
              style={{ display: "none" }}
            />
            {selectedImage ? (
              <img src={selectedImage} width={75} />
            ) : (
              <Image fontSize={25} fill="#ffffff" />
            )}
          </div>
          <div className="tweet" onClick={addComment}>
            {uploading ? <Loading /> : "Comment"}
          </div>
        </div>
      </div>
      {
        //Feed Section
        comments.map((comment) => (
          <div className="feedComment" key={comment.commentId}>
            <Avatar
              isRounded
              image={comment.userImage}
              theme="image"
              size={60}
            />
            <div className="completeComment">
              <div className="who">
                {comment.userName}
                {accountType.match("Blue_Type") ? (
                  <img className="img-verify" src={blue} alt="Account_Type" />
                ) : (
                  <div></div>
                )}
                {accountType.match("Golden_Type") ? (
                  <img className="img-verify" src={gold} alt="Account_Type" />
                ) : (
                  <div></div>
                )}
                <div className="accWhen">{comment.userAd}</div>
                <div className="accWhen">{comment.date}</div>
                <div>
                  {comment.userAd.match(activeAccount) ? (
                    <div className=""></div>
                  ) : (
                    <div
                      className="follow"
                      onClick={() => follow(comment.userAd)}
                    >
                      Follow
                    </div>
                  )}
                </div>
               
              </div>
              <div className="commentContent">
                  <p style={{ color: "black" }}>{comment.commentText}</p>
                  {comment.commentImg !== "" && (
                    <img
                      src={comment.commentImg}
                      className="tweetImg"
                      alt="Type Of Blab"
                    />
                  )}
            </div>
            </div>
           
          </div>
          
        ))
      }
    </div>
  );
}
export default Comment;
