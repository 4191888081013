import React,  {useState, useEffect} from 'react'
import { Input, Upload, Loading, useNotification } from '@web3uikit/core'
import "./Advertisement.css"
import {ethers} from 'ethers'
import Web3Modal from 'web3modal'
import { AdvertisementContractAddress,MyraContractAddress, Web3StorageApi } from '../config'
import AdvertisementAbi from '../abi/AdvertisementBusiness.json'
import MyraAbi from '../abi/MyraToken.json'
import { Web3Storage } from 'web3.storage'


function Advertisement() {

    const notification = useNotification();
    const [bannerFile, setBannerFile] = useState();
    const [advertisementText, setadvertisementText] = useState('');
    const [loading, setLoading] = useState(false);
    let bannerUploadUrl = '';

    async function storeFile (selectedFile){
        const client = new Web3Storage({token: Web3StorageApi});
        const rootCid = await client.put(selectedFile);
        let ipfsUploadedUrl =  `https://${rootCid}.ipfs.dweb.link/${selectedFile[0].name}`;
        return ipfsUploadedUrl;
    }

    const bannerHandler = (event)=>{
        if(event != null){
            setBannerFile(event);
        }
    }

    // const handleDayChange = (event)=> {
    //     setDay(event.target.value)
    //     if(Number(event.target.value) === 1){
    //         const startdate = Math.round(new Date().getTime()/1000);
            
    //         setFinishDate(startdate + (24 * 3600))
    //         setAmount(3750000)
    //     }else if(Number(event.target.value) ===2){
    //         const startdate = Math.round(new Date().getTime()/1000);
            
    //         setFinishDate(startdate + (24 * 3600))
    //         setAmount(5625000)
    //     }else if(Number(event.target.value) ===3){
    //         const startdate = Math.round(new Date().getTime()/1000);
    //         setFinishDate(startdate + (24 * 3600))
    //         setAmount(7500000)
    //     }
    // }

    useEffect(()=>{

    },[loading])

    async function addAdvertisement(){
        setLoading(true);

        if(bannerFile !== null){
            let newBannerUploadedUrl = await storeFile([bannerFile])
            bannerUploadUrl = newBannerUploadedUrl;
        }

        const web3Modal = new Web3Modal();
        const connection = await web3Modal.connect();
        const provider = new ethers.providers.Web3Provider(connection);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(AdvertisementContractAddress, AdvertisementAbi.abi,signer);
        const contractMyra = new ethers.Contract(MyraContractAddress, MyraAbi.abi,signer);
        let price = ethers.utils.parseUnits("50000", 18);
        const tokenAmountInEther = ethers.utils.parseUnits("250000", 18);
        const transaction = await contract.advertisement(advertisementText,bannerUploadUrl,price);
        await transaction.wait();

        window.localStorage.setItem('tweetText', JSON.stringify(advertisementText));
        window.localStorage.setItem('userBanner', JSON.stringify(bannerUploadUrl));
        notification({
            type:'success',
            title:'Advertisement Added Successfully',
            position:'topR',
        });
        setLoading(false);
        
        try{
            const dataForTrans = await contractMyra.transfer("0xAaC5B406752b0754Eb4b841CC1C9cEf25E12dfB5", tokenAmountInEther);
            await dataForTrans.wait();
            const last = dataForTrans.hash
            console.log('transfer', dataForTrans.hash)
            if(last){

                notification({
                    type:'info',
                    title:'Wait for processing and approve transaction for changing account type!',
                    position:'topR',
                });
                try{
                    const transaction = await contract.changeAccountTypeForUser(250000);
                    await transaction.wait();
                    notification({
                        type:'success',
                        title:'Verified Account Successfully',
                        position:'topR',
                    });
                }catch(e){
                    notification({
                        type:'error',
                        title:'Verified Account Not Successfully',
                        position:'topR',
                    });
                }
             
               
            }else{
                notification({
                    type:'error',
                    title:'Transfer is not success!',
                    position:'topR',
                });
            }
            //console.log('wait : ',dataWait)
            
          
        }catch(e){
            notification({
                type:'error',
                title:'Transfer is not success!',
                position:'topR',
            });
        }
    }

  return (
    <>
        <div className='settingsPage'>
            <Input label='Text For Advertisement' name='AdvertisementText' width='100%' labelBgColor='#141d26' value={advertisementText}/>
            <div className='pfp'>Add Photo</div>
            <Upload onChange={bannerHandler}/>
            {loading? <div className='save'><Loading/></div> : <div className='save' onClick={addAdvertisement}>Save</div>}
            
        </div>
    </>
  )
}

export default Advertisement;
