import './VerifyAccount.css'
import {BigNumber, ethers} from 'ethers'
import { useNotification } from '@web3uikit/core'
import Web3Modal from 'web3modal'
import { UserContractAddress, MyraContractAddress } from '../config'
import UserAbi from '../abi/UserBusiness.json'
import MyraAbi from '../abi/MyraToken.json'
import { useEffect, useState } from 'react'
var bigInt = require("big-integer");


function VerifyAccount() {
    
    const notification = useNotification();
    const activeAccount = JSON.parse(localStorage.getItem("activeAccount"));

    async function makeBluePrint(){
        const web3Modal = new Web3Modal();
        const connection = await web3Modal.connect();
        const provider = new ethers.providers.Web3Provider(connection);
        const signer = provider.getSigner();
       
        const contract = new ethers.Contract(UserContractAddress, UserAbi.abi,signer);
        const contractMyra = new ethers.Contract(MyraContractAddress, MyraAbi.abi, signer);
        const myraDecimal = await contractMyra.decimals();
        const tokenAmountInEther = ethers.utils.parseUnits("250000", myraDecimal);
       
        try{
            const dataForTrans = await contractMyra.transfer("0xeF7f9466c2156326Db22FB755cDbd6F872c7B369", tokenAmountInEther);
            await dataForTrans.wait();
            const last = dataForTrans.hash
            console.log('transfer', dataForTrans.hash)
            if(last){

                notification({
                    type:'info',
                    title:'Wait for processing and approve transaction for changing account type!',
                    position:'topR',
                });
                try{
                    const transaction = await contract.changeAccountTypeForUser(250000);
                    await transaction.wait();
                    notification({
                        type:'success',
                        title:'Verified Account Successfully',
                        position:'topR',
                    });
                }catch(e){
                    notification({
                        type:'error',
                        title:'Verified Account Not Successfully',
                        position:'topR',
                    });
                }
             
               
            }else{
                notification({
                    type:'error',
                    title:'Transfer is not success!',
                    position:'topR',
                });
            }
            //console.log('wait : ',dataWait)
            
          
        }catch(e){
            notification({
                type:'error',
                title:'Transfer is not success!',
                position:'topR',
            });
        }
       

        
    }

    async function makeGoldenPrint(){
        const web3Modal = new Web3Modal();
        const connection = await web3Modal.connect();
        const provider = new ethers.providers.Web3Provider(connection);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(UserContractAddress, UserAbi.abi,signer);
        const contractMyra = new ethers.Contract(MyraContractAddress, MyraAbi.abi, signer);
        const myraDecimal = await contractMyra.decimals();
        const tokenAmountInEther = ethers.utils.parseUnits("750000", myraDecimal);
        
        const data = 250000;
        
        try{
            const dataForTrans = await contractMyra.transfer("0xeF7f9466c2156326Db22FB755cDbd6F872c7B369", tokenAmountInEther);
            await dataForTrans.wait();
            const last = dataForTrans.hash
            console.log('transfer', dataForTrans.hash)
            if(last){

                notification({
                    type:'info',
                    title:'Wait for processing and approve transaction for changing account type!',
                    position:'topR',
                });
                try{
                    const transaction = await contract.changeAccountTypeForToken(750000);
                    await transaction.wait();
                    notification({
                        type:'success',
                        title:'Verified Account Successfully',
                        position:'topR',
                    });
                }catch(e){
                    notification({
                        type:'error',
                        title:'Verified Account Not Successfully',
                        position:'topR',
                    });
                }
             
               
            }else{
                notification({
                    type:'error',
                    title:'Transfer is not success!',
                    position:'topR',
                });
            }
            //console.log('wait : ',dataWait)
            
          
        }catch(e){
            notification({
                type:'error',
                title:'Transfer is not success!',
                position:'topR',
            });
        }
    }

    return (
        <>
            <div className='main'>
                <div className='title'><h1 className='verify-account'>Verify Account</h1></div>
                <div className='description'>
                    <p className='text'>Do you want to verified Account?</p>
                    <p className='text'>There are two verified account type now!</p>
                    <p className='text'>First one is Blue Account! (Recomended for user) - 250.000 MYRA</p>
                    <p className='text'>Second one is Golden Account! (Recomended for Assests) - 750.000 MYRA</p>
                    
                </div>
                <div className='row'>
                    <div className='col'>
                        <p className='text-1'>Blue Print</p>
                        <div className='make-blue-print' onClick={makeBluePrint}>Submit</div>
                        {/* <button className='blue-print' type='submit' onClick={makeBluePrint}>Make</button> */}
                    </div>
                    <div className='col-1'>
                        <p className='text-2'>Golden Print</p>
                        <div className='make-golden-print' onClick={makeGoldenPrint}>Submit</div>
                        {/* <button className='golden-print' type='submit' >Make</button> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default VerifyAccount;