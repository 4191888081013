import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import "./ProfileInDetail.css";
import "./MessageSender.css";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { Loading, useNotification } from '@web3uikit/core'
import blue from "../images/blue.png";
import { Image } from '@web3uikit/icons'
import gold from "../images/gold.png";
import {Web3Storage} from 'web3.storage'
import UserAbi from "../abi/UserBusiness.json";
import MessageAbi from "../abi/MessageBusiness.json";
import { MessageCircle } from "@web3uikit/icons";
import {
  UserContractAddress,
  MessageContractAddress,
  Web3StorageApi,
} from "../config";

function MessageSender() {
    const inputFile = useRef(null);
    const[messageText, setMessageText] = useState('');
  const [username, setUsername] = useState("");
  const [bio, setBio] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [profileBanner, setProfileBanner] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const activeAccount = JSON.parse(localStorage.getItem("activeAccount"));
  const [accountType, setAccountType] = useState("no_data");
  const [selectedFile, setSelectedFile] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [uploading, setUploading] = useState(false);
  let ipfsUploadedUrl = '';
  const notification = useNotification();
  let { account } = useParams();

  async function storeFile (){
    const client = new Web3Storage({token: Web3StorageApi});
    const rootCid = await client.put(selectedFile);
    ipfsUploadedUrl =  `https://${rootCid}.ipfs.dweb.link/${selectedFile[0].name}`;
}

const onImageClick = () => {
    inputFile.current.click();
}

const changeHandler = (event) => {
    const imgFile = event.target.files[0];
    setSelectedImage(URL.createObjectURL(imgFile));
    setSelectedFile(event.target.files);
}
useEffect(()=>{
    getUserDetail();
})
  async function getUserDetail() {
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      UserContractAddress,
      UserAbi.abi,
      signer
    );
    const dataGetUser = await contract.getUser(account);
    const dataAccountType = await contract.getAccountType(account);
    setUsername(dataGetUser.name);
    setBio(dataGetUser.bio);
    setProfileImage(dataGetUser.profileImg);
    setProfileBanner(dataGetUser.profileBanner);
    setUserAddress(account);
    setAccountType(dataAccountType);
  }
  
  async function sendMessage(){
      if(messageText.trim().length < 1){
          notification({
              type:'warning',
              message:'^Minimum 1 characters',
              title:'Message field required',
              position:'topR'
          });
          return;
      }
      setUploading(true);
      if(selectedImage){
          await storeFile();
      }
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
          MessageContractAddress,
          MessageAbi.abi,
          signer);

      try{
          const transaction = await contract.sendMessage(userAddress,messageText,ipfsUploadedUrl);
          await transaction.wait();
          notification({
              type:'success',
              title:'Message Sent Successfully',
              position:'topR'
          });
          setSelectedImage(null);
          setMessageText('');
          setSelectedFile(null);
          setUploading(false);
      }catch(error){
          notification({
              type:'error',
              title:'Transaction Error',
              message:error.message,
              position:'topR'
          });
          setUploading(false);
      }
    }
  return (
    <>
      <div>
        {
          <div>
            <img className="profilePFP" src={profileImage} />
            <div className="profileName">
              {username}

              {accountType.match("Blue_Type") ? (
                <img className="img-verify" src={blue} />
              ) : (
                <div></div>
              )}
              {accountType.match("Golden_Type") ? (
                <img className="img-verify" src={gold} />
              ) : (
                <div></div>
              )}
            </div>
            <div className="profileWallet">{userAddress}</div>
          </div>
        }
        <div>
        <div className='profileTweet'>
                <div className='tweetSection'>
                   
                    <textarea value={messageText} className='textArea'  placeholder='Whats going on?' name='TweetTxtArea' onChange={(e)=> setMessageText(e.target.value)}></textarea>
                </div>
                <div className='tweetSection'>
                    <div className='imgDiv' onClick={onImageClick}>
                        <input type='file' ref={inputFile} onChange={changeHandler} style={{display:"none"}}/>
                        {selectedImage ? <img src={selectedImage} width={150}/>:<Image fontSize={25} fill='#ffffff' /> }
                        
                    </div>
                    <div className='tweet' onClick={sendMessage}>{uploading ? <Loading/> : 'Send'}</div>
                </div>
            </div>
        </div>

        <div>
          <div>
              <img className="profilePFP" src={profileImage} />
              <div className="profileName">
                {username}

                {accountType.match("Blue_Type") ? (
                  <img className="img-verify" src={blue} />
                ) : (
                  <div></div>
                )}
                {accountType.match("Golden_Type") ? (
                  <img className="img-verify" src={gold} />
                ) : (
                  <div></div>
                )}
              </div>
              <div className="profileWallet">{userAddress}</div>
            </div>
            <div>
                
            </div>
        </div>
      </div>
    </>
  );
}
export default MessageSender;
