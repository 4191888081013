import React from 'react'
import './SideBar.css'
import { Twitter, Cube, User, Cog,Balancer, Toolbox, MessageCircle } from '@web3uikit/icons'
import { Link } from 'react-router-dom'
import myraLogo from '../images/blab_logo.png'

function SideBar() {
  return (
    <>
    <div className='siderContent'>
      <div className='menu'>
        <Link to="/" className='link'>
        <div className='menuItems'>
          <img src={myraLogo} style={{width:'70px', height:'70px;'}}/>
          BLABBER
        </div>
        </Link>
        <Link to="/" className='link'>
          <div className='menuItems'>
            <Cube fontSize="50px"/> Home
          </div>
        </Link>

        <Link to="/profile" className='link'>
          <div className='menuItems'>
            <User fontSize="50px"/> Profile
          </div>
        </Link>

        <Link to="/settings" className='link'>
          <div className='menuItems'>
            <Cog fontSize="50px"/> Settings
          </div>
        </Link>

        {/* <Link to="/advertisement" className='link'>
          <div className='menuItems'>
            <Toolbox fontSize="50px"/> Advertisement
          </div>
        </Link> */}

        <Link to="/verifyAccount" className='link'>
          <div className='menuItems'>
            <Balancer fontSize="50px"/> Verify Account
          </div>
        </Link>

        {/* <Link to="/messages" className='link'>
          <div className='menuItems'>
            <MessageCircle fontSize="50px"/> Messages
          </div>
        </Link> */}

      </div>
    </div>
    </>
  )
}

export default SideBar;