import React, {useState, useRef} from 'react'
import "./Home.css"
import { Avatar, Loading, useNotification } from '@web3uikit/core'
import { Image } from '@web3uikit/icons'
// import { defaultImgs } from '../defaultImgs'
import BlabInFeed from '../components/BlabInFeed'
import { ethers } from 'ethers'
import Web3Modal from 'web3modal'
import { BlabContractAddress, UserContractAddress, Web3StorageApi } from '../config'

import BlabAbi from '../abi/BlabBusiness.json'
import {Web3Storage} from 'web3.storage'
import RightBar from '../components/RightBar'

function Home() {
    const inputFile = useRef(null);
    const [selectedImage, setSelectedImage] = useState();
    const [tweetText, setTweetText] = useState('');
    const UserImage = JSON.parse(localStorage.getItem('userImage'));
    const [selectedFile, setSelectedFile] = useState();
    const [uploading, setUploading] = useState(false);
    let ipfsUploadedUrl = '';
    const notification = useNotification();

    async function storeFile (){
        const client = new Web3Storage({token: Web3StorageApi});
        const rootCid = await client.put(selectedFile);
        ipfsUploadedUrl =  `https://${rootCid}.ipfs.dweb.link/${selectedFile[0].name}`;
    }

    const onImageClick = () => {
        inputFile.current.click();
    }

    const changeHandler = (event) => {
        const imgFile = event.target.files[0];
        setSelectedImage(URL.createObjectURL(imgFile));
        setSelectedFile(event.target.files);
    }

    async function addBlab(){
        if(tweetText.trim().length < 5){
            notification({
                type:'warning',
                message:'^Minimum 5 characters',
                title:'Tweet field required',
                position:'topR'
            });
            return;
        }
        setUploading(true);
        if(selectedImage){
            await storeFile();
        }
        const web3Modal = new Web3Modal();
        const connection = await web3Modal.connect();
        const provider = new ethers.providers.Web3Provider(connection);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(BlabContractAddress, BlabAbi.abi,signer);

        try{
            const transaction = await contract.addBlab(tweetText,ipfsUploadedUrl, 0, 200);
            await transaction.wait();
            notification({
                type:'success',
                title:'Blab Added Successfully',
                position:'topR'
            });
            setSelectedImage(null);
            setTweetText('');
            setSelectedFile(null);
            setUploading(false);
        }catch(error){
            notification({
                type:'error',
                title:'Do you Hold MYRA?',
                
                position:'topR'
            });
            setUploading(false);
        }
    }

  return (
    <>
        <div className='mainContent'>
            <div className='profileTweet'>
                <div className='tweetSection'>
                    <Avatar isRounded image={UserImage} theme='image' size={60}/>
                    <textarea value={tweetText} style={{color:'white;'}} className='textArea'  placeholder='Whats going on?' name='TweetTxtArea' onChange={(e)=> setTweetText(e.target.value)}></textarea>
                </div>
                <div className='tweetSection'>
                    <div className='imgDiv' onClick={onImageClick}>
                        <input type='file' ref={inputFile} onChange={changeHandler} style={{display:"none"}}/>
                        {selectedImage ? <img src={selectedImage} width={150}/>:<Image fontSize={25} fill='#ffffff' /> }
                        
                    </div>
                    <div className='tweet' onClick={addBlab}>{uploading ? <Loading/> : 'Blab'}</div>
                </div>
            </div>
            {
                //Feed Section
               
            }
            
            <BlabInFeed profile={false} reload={uploading}/>
            
        </div>
    </>
  )
}

export default Home;
