import React, {useState, useRef, useEffect} from 'react'
import "./BlabInTags.css"
import {Link, useParams } from 'react-router-dom';
import { Avatar, Loading, useNotification } from '@web3uikit/core'
import { Image } from '@web3uikit/icons'
import { MessageCircle, Bin } from "@web3uikit/icons";
import { ethers } from 'ethers'
import Web3Modal from 'web3modal'
import blue from "../images/blue.png";
import gold from "../images/gold.png";
import UserAbi from "../abi/UserBusiness.json";
import { BlabContractAddress,FollowingContractAddress, UserContractAddress, Web3StorageApi } from '../config'
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import IconButton from "@mui/material/IconButton";
import BlabAbi from '../abi/BlabBusiness.json'
import {Web3Storage} from 'web3.storage'
import FollowAbi from "../abi/FollowingBusiness.json";
import Comment from "../pages/Comment";
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";


function BlabInTags() {
 const onlyUser = false;
  const inputFile = useRef(null);
  const activeAccount = JSON.parse(localStorage.getItem("activeAccount"));
  const [tweets, setTweets] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const [loadingState, setLoadingState] = useState("not-loaded");
  const [tweetText, setTweetText] = useState('');
  const [userAddress, setUserAddress] = useState(0);
  const UserImage = JSON.parse(localStorage.getItem('userImage'));
  const [selectedFile, setSelectedFile] = useState();
  const [uploading, setUploading] = useState(false);
  const [like, setLike] = useState(0);
  const [dislike, setDislike] = useState(0);
  const [accountType, setAccountType] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  let ipfsUploadedUrl = '';
  const notification = useNotification();
  let {tagid} = useParams();

  async function storeFile (){
      const client = new Web3Storage({token: Web3StorageApi});
      const rootCid = await client.put(selectedFile);
      ipfsUploadedUrl =  `https://${rootCid}.ipfs.dweb.link/${selectedFile[0].name}`;
  }

  const onImageClick = () => {
      inputFile.current.click();
  }

  const changeHandler = (event) => {
      const imgFile = event.target.files[0];
      setSelectedImage(URL.createObjectURL(imgFile));
      setSelectedFile(event.target.files);
  }

  useEffect(() => {
    loadAllBlabsByTagId();
    //getUserDetail();
  }, []);

  // async function getUserDetail() {
  //   const web3Modal = new Web3Modal();
  //   const connection = await web3Modal.connect();
  //   const provider = new ethers.providers.Web3Provider(connection);
  //   const signer = provider.getSigner();
  //   const contract = new ethers.Contract(
  //     UserContractAddress,
  //     UserAbi.abi,
  //     signer
  //   );
  //   const data = await contract.getUser(activeAccount);
  // }

  async function addBlab(){
    if(tweetText.trim().length < 5){
        notification({
            type:'warning',
            message:'^Minimum 5 characters',
            title:'Tweet field required',
            position:'topR'
        });
        return;
    }
    setUploading(true);
    if(selectedImage){
        await storeFile();
    }
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(BlabContractAddress, BlabAbi.abi,signer);

    try{
        const transaction = await contract.addBlab(tweetText,ipfsUploadedUrl, tagid, 2500);
        await transaction.wait();
        notification({
            type:'success',
            title:'Blab Added Successfully',
            position:'topR'
        });
        setSelectedImage(null);
        setTweetText('');
        setSelectedFile(null);
        setUploading(false);
    }catch(error){
        notification({
            type:'error',
            title:'Do you hold MYRA?',
            
            position:'topR'
        });
        setUploading(false);
    }
}

async function loadAllBlabsByTagId() {
  const web3Modal = new Web3Modal();
  const connection = await web3Modal.connect();
  const provider = new ethers.providers.Web3Provider(connection);
  const signer = provider.getSigner();
  const contract = new ethers.Contract(
    BlabContractAddress,
    BlabAbi.abi,
    signer
  );
  const data = await contract.getBlabsByTagId(tagid);

  const result = await Promise.all(
    data.map(async (blab) => {
      const unitTime = blab.timestamp;
      const date = new Date(unitTime * 1000);
      const blabDate = date.toLocaleDateString("fr-CH");
      const userContract = new ethers.Contract(
        UserContractAddress,
        UserAbi.abi,
        signer
      );
      const getUserDetail = await userContract.getUser(blab.blaber);
      const getAccountType = await userContract.getAccountType(blab.blaber);

      let item = {
        tweeter: blab.blaber,
        id: blab.id,
        tweetText: blab.blabText,
        tweetImg: blab.blabImg,
        isDeleted: blab.isDeleted,
        userName: getUserDetail["name"],
        userImage: getUserDetail["profileImg"],
        date: blabDate,
        like: parseInt(blab.like),
        dislike: parseInt(blab.dislike),
      };
      setAccountType(getAccountType);

      setUserAddress(blab.blaber);
      return item;
    })
  );

  setTweets(result.reverse());
  setLoadingState("loaded");
}

async function likeTweet(tweetId) {
  const web3Modal = new Web3Modal();
  const connection = await web3Modal.connect();
  const provider = new ethers.providers.Web3Provider(connection);
  const signer = provider.getSigner();
  const contract = new ethers.Contract(
    BlabContractAddress,
    BlabAbi.abi,
    signer
  );
  const data = await contract.like(tweetId,2500);
  await data.wait();

  if (data === 0) {
    notification({
      type: "warnning",
      title: "You cannot like secondly",
      position: "topR",
    });
  }
  notification({
    type: "success",
    title: "Tweet Like successfuly",
    position: "topR",
  });
  loadAllBlabsByTagId();
  setLike(like + 1);
}

async function dislikeTweet(tweetId) {
  const web3Modal = new Web3Modal();
  const connection = await web3Modal.connect();
  const provider = new ethers.providers.Web3Provider(connection);
  const signer = provider.getSigner();
  const contract = new ethers.Contract(
    BlabContractAddress,
    BlabAbi.abi,
    signer
  );
  const data = await contract.dislike(tweetId,2500);
  await data.wait();
  if (data === 0) {
    notification({
      type: "warnning",
      title: "You cannot dislike secondly",
      position: "topR",
    });
  }
  notification({
    type: "success",
    title: "Tweet Dislike successfuly",
    position: "topR",
  });
  loadAllBlabsByTagId();
  setDislike(dislike + 1);
}

async function follow(userAddress1) {
  const web3Modal = new Web3Modal();
  const connection = await web3Modal.connect();
  const provider = new ethers.providers.Web3Provider(connection);
  const signer = provider.getSigner();
  const contract = new ethers.Contract(
    FollowingContractAddress,
    FollowAbi.abi,
    signer
  );
  const data = await contract.follow(userAddress1);
}  


const handleItemClick = (tweet) => {
  setSelectedItem(tweet);
};

const handleToClose = () => {
  setSelectedItem(null);
};

  return (
    <>
       <div className='mainContent'>
            <div className='profileTweet'>
                <div className='tweetSection'>
                    <Avatar isRounded image={UserImage} theme='image' size={60}/>
                    <textarea value={tweetText} className='textArea'  placeholder='Whats going on?' name='TweetTxtArea' onChange={(e)=> setTweetText(e.target.value)}></textarea>
                </div>
                <div className='tweetSection'>
                    <div className='imgDiv' onClick={onImageClick}>
                        <input type='file' ref={inputFile} onChange={changeHandler} style={{display:"none"}}/>
                        {selectedImage ? <img src={selectedImage} width={150}/>:<Image fontSize={25} fill='#ffffff' /> }
                        
                    </div>
                    <div className='tweet' onClick={addBlab}>{uploading ? <Loading/> : 'Blab'}</div>
                </div>
            </div>
            {
                //Feed Section
               
            }
            
            {tweets.map((blab) => (
        <div className="feedTweet" key={blab.id}>
          <Avatar isRounded image={blab.userImage} theme="image" size={60} />
          <div className="completeTweet">
            <div className="who">
              {blab.userName}

              {accountType.match("Blue_Type") ? (
                <img className="img-verify" src={blue} alt="Account_Type" />
              ) : (
                <div></div>
              )}
              {accountType.match("Golden_Type") ? (
                <img className="img-verify" src={gold} alt="Account_Type" />
              ) : (
                <div></div>
              )}
              <div className="accWhen"><a className="accWhenLink" href={"profileUser/"+blab.tweeter}>{blab.tweeter}</a></div>
              <div className="accWhen">{blab.date}</div>
              <div>
                {blab.tweeter.match(activeAccount) ? (
                  <div className=""></div>
                ) : (
                  <div className="follow" onClick={() => follow(blab.tweeter)}>
                    Follow
                  </div>
                )}
              </div>
            </div>
            <div className="tweetContent">
              {blab.tweetText}
              {blab.tweetImg !== "" && (
                <img
                  src={blab.tweetImg}
                  className="tweetImg"
                  alt="Type Of Blab"
                />
              )}
            </div>
            <div className="interactions">
              <div className="interactionNums">
              <div onClick={()=> handleItemClick(blab)}>
                  <MessageCircle fontSize={20} />
                  {selectedItem && selectedItem.id === blab.id &&(
                    <Dialog className="dialog" open={true} onClose={handleToClose}>
                        <DialogTitle>{blab.text}</DialogTitle>
                        <DialogContent><Comment blab={blab.id}/></DialogContent>
                      
                    </Dialog>
                )}
                </div>
               
              </div>

              

              {/* <div className='interactionNums'>
                        <Calendar fontSize={20}/>{tweet.date}
                    </div> */}

              {
                onlyUser ? (
                  <div className="interactionNums">
                    
                  </div>
                ) : (
                  <div className="likeOrDislike">
                    <IconButton
                      className="likebutton"
                      onClick={() => likeTweet(blab.id)}
                    >
                      <ThumbUpIcon></ThumbUpIcon>
                      <p className="likeTweet">{blab.like}</p>
                    </IconButton>
                    <IconButton onClick={() => dislikeTweet(blab.id)}>
                      <ThumbDownIcon></ThumbDownIcon>
                      <p className="dislikeTweet">{blab.dislike}</p>
                    </IconButton>
                  </div>
                )

                //<Matic fontSize={20}/>
                /*
                        <div className=''>
                    
                        <div className='interactionNums'><ThumbDownIcon/>1</div>
                        </div>*/
              }
            </div>
          </div>
        </div>
      ))}
            
        </div>
    </>
  )
}

export default BlabInTags;
