import React, { useState, useEffect, useRef } from 'react'
import Web3Modal from "web3modal";
import { MessageContractAddress, UserContractAddress,Web3StorageApi } from '../config';
import MessageAbi from '../abi/MessageBusiness.json';
import UserAbi from '../abi/UserBusiness.json';
import { ethers } from "ethers";
import './Message.css';
import {Web3Storage} from 'web3.storage'
import { useNotification } from "@web3uikit/core";
function Message() {
    const inputFile = useRef(null);
    const [messages, setMessages] = useState([]);
    const [messageText, setMessageText] = useState('');
    const [selectedImage, setSelectedImage] = useState();
    const [accountType, setAccountType] = useState("");
    const [selectedFile, setSelectedFile] = useState();
    const [selectedItem, setSelectedItem] = useState(null);
    const [uploading, setUploading] = useState(false);
    const[userAddress, setUserAddress] = useState();
    const notification = useNotification();

    let ipfsUploadedUrl = "";

    async function storeFile (){
        const client = new Web3Storage({token: Web3StorageApi});
        const rootCid = await client.put(selectedFile);
        ipfsUploadedUrl =  `https://${rootCid}.ipfs.dweb.link/${selectedFile[0].name}`;
    }

    const onImageClick = () => {
        inputFile.current.click();
    }

    const changeHandler = (event) => {
        const imgFile = event.target.files[0];
        setSelectedImage(URL.createObjectURL(imgFile));
        setSelectedFile(event.target.files);
    }

    useEffect(() => {
        getMessages();
      }, []);

 
  
  async function getMessages(){
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      MessageContractAddress,
      MessageAbi.abi,
      signer
    );
    const data = await contract.showReceivedMessage();

    const result = await Promise.all(
      data.map(async (message) => {
        const unitTime = message.timestamp;
        const date = new Date(unitTime * 1000);
        const messageDate = date.toLocaleDateString("fr-CH");
        const userContract = new ethers.Contract(
          UserContractAddress,
          UserAbi.abi,
          signer
        );
        const getUserDetail = await userContract.getUser(message.messageReceiver);
        const getAccountType = await userContract.getAccountType(message.messageReceiver);

        let item = {
          messageId:message.messageId,
          sender:message.messageSender,
          receiver: message.messageReceiver,
          messageText: message.messageText,
          messageImg: message.messageImg,
          isDeleted: message.isDeleted,
          userName: getUserDetail["name"],
          userImage: getUserDetail["profileImg"],
          date: messageDate,
        };
        setAccountType(getAccountType);

        setUserAddress(message.messageReceiver);
        return item;
      })
    );
    setMessages(result.reverse());
  }

  return (
    <>
       
    </>
  )
}

export default Message;