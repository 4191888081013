import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import "./Profile.css"
import BlabInFeed from '../components/BlabInFeed'
import {ethers} from 'ethers'
import Web3Modal from 'web3modal'
import {Matic} from '@web3uikit/icons'
import blue from '../images/blue.png'
import gold from '../images/gold.png'
import UserAbi from '../abi/UserBusiness.json'
import FollowAbi from '../abi/FollowingBusiness.json'
import CommentAbi from "../abi/CommentBusiness.json";

import { UserContractAddress,CommentContractAddress,FollowingContractAddress, Web3StorageApi } from '../config'
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

function Profile() {
  const [followSystem, setFollowSystem] = useState([])
  const [followerSystem, setFollowerSystem] = useState([])
  const activeAccount = JSON.parse(localStorage.getItem('activeAccount'));
  const userName = JSON.parse(localStorage.getItem('userName'));
  const userBio = JSON.parse(localStorage.getItem('userBio'));
  const userBanner = JSON.parse(localStorage.getItem('userBanner'));
  const userImage = JSON.parse(localStorage.getItem('userImage'));

  const [followLength, setFollowLength] = useState(0)
  const [followerLength, setFollowerLength] = useState(0)
  const [open, setOpen] = useState()
  const [accountBalance, setAccountBalance] = useState(0);
  const [accountType, setAccountType] = useState('no_data');
  const [selectPage,setSelectPage] = useState(0);

  useEffect(()=>{
    getAccountBalance();
    getUserDetail();
    showFollows();
    showFollowers();
  },[]);
  async function getAccountBalance(){
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    let provider = new ethers.providers.Web3Provider(connection);
    let balance = await provider.getBalance(activeAccount);
    balance = ethers.utils.formatEther(balance).substring(0,4);
    setAccountBalance(balance);
  }

    async function getUserDetail(){
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(UserContractAddress, UserAbi.abi,signer);
      //const data = await contract.getUser(activeAccount);
      const data = await contract.getAccountType(activeAccount);
      setAccountType(data)
    }

    async function showFollows(){
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(FollowingContractAddress, FollowAbi.abi,signer);
      const data = await contract.showFollow();

      const result = await Promise.all(data.map(async followSystem=>{
        

        let item ={
            follower : followSystem.follow,
            id: followSystem.followingId,
            follow: followSystem.follower,
            isDeleted:followSystem.isDeleted,
        };
        return item;
        
    }));
    setFollowLength(result.length)
    setFollowSystem(result.reverse());
  }

  async function showFollowers(){
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(FollowingContractAddress, FollowAbi.abi,signer);
    const data = await contract.showFollower();

    const result = await Promise.all(data.map(async followerSystem=>{
      

      let item ={
          follower : followerSystem.follow,
          id: followerSystem.followingId,
          follow: followerSystem.follower,
          isDeleted:followerSystem.isDeleted,
      };
      return item;
  }));
  setFollowerLength(result.length)
  setFollowerSystem(result.reverse());
}

const handleClickToOpen = () => {
  setOpen(true);
};

const handleToClose = () => {
  setOpen(false);
};



 
  return (
    <>
        <img className='profileBanner' src={userBanner}/>
        <div className='pfpContainer'>
            <img className='profilePFP' src={userImage}/>
            <div className='profileName'>
              {userName}
              
              
                {
                  accountType.match("Blue_Type") ? <img className='img-verify' src={blue}  /> : <div></div>
                }
                {
                  accountType.match("Golden_Type") ? <img className='img-verify' src={gold}  /> : <div></div>
                }
                 
              </div>
            <div>
            {/* <FontAwesomeIcon icon={faEnvelope} style={{color: "#3ac214",}} /> */}
            
            
            </div>
            <div className='profileWallet'>{activeAccount}</div>
           
            <Link to='/settings'>
                <div className='profileEdit'>Edit Profile</div>
            </Link>
            <div className='profileBio'>{userBio}</div>
            
            <div className='following'>
              {
                
              //   <div className='buttons-following'>
              //     <a className='follow-button' onClick={handleClickToOpen}>Follows {followLength}</a>
              //     <Dialog open={open} onClose={handleToClose}>
              //       <DialogTitle>Follows People</DialogTitle>
              //       <DialogContent>
              //         {
              //             followSystem.map((follow, i)=>(
              //               <DialogContentText>{follow.follow}</DialogContentText>
              //             ))
              //         }
                      
              //       </DialogContent>
              //     </Dialog>
              //     <a className='follower-button' onClick={handleClickToOpen}>Followers {followerLength}</a>
              //     <Dialog open={open} onClose={handleToClose}>
              //       <DialogTitle>Followers People</DialogTitle>
              //       <DialogContent>
              //         {
              //             followerSystem.map((follower, i)=>(
              //               <DialogContentText>{follower.follower}</DialogContentText>
                            
              //             ))
              //         }
                      
              //       </DialogContent>
              //     </Dialog>
              // </div>  
              }
            </div>
            
            <div className='profileTabs'>
                <div className='profileTab'>
                  Blabs</div>
                
               
            </div>
        </div>
        {
          
        }
        {<BlabInFeed profile={true}></BlabInFeed> }
       
    </>
  )
}

export default Profile;
