import React, {useState, useEffect} from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Settings from './pages/Settings';
import Message from './pages/Message';
import './App.css';
import SideBar from './components/SideBar';
import RightBar from './components/RightBar';
import { Button, useNotification, Loading } from '@web3uikit/core';
import {Twitter, Metamask} from '@web3uikit/icons'
import { ethers,utils } from 'ethers';
import Web3Modal from 'web3modal'
import { UserContractAddress } from './config'
import UserAbi from './abi/UserBusiness.json'
import Advertisement from './pages/Advertisement';
import VerifyAccount from './pages/VerifyAccount';
import ProfileInDetail from './pages/ProfileInDetail';
import MessageSender from './pages/MessageSender';
import BlabInTags from './pages/BlabInTags';
import blabLogo from '../src/images/blab_logo.png'
var toonavatar = require('cartoon-avatar')

//const { ethers, utils }  = require("ethers")

function App() {
  const [isAuthenticated,setIsAuthenticated] = useState(false);
  const [provider, setProvider] = useState(window.ethereum);
  const notification = useNotification();
  const [loading, setLoadingState] = useState(false);

  const warningNotification = ()=>{
    notification({
      type:'warning',
      message:'Change network to bsc to visit this site',
      title: 'Switch to Binance Smart Chain Network',
      position: 'topR'
    });
  }

  const infoNotification = (accountNumber)=>{
    notification({
      type:'info',
      message:accountNumber,
      title: 'Connected to Binance Smart Chain Network',
      position: 'topR'
    });
  }

  useEffect(()=>{
    if(!provider){
      window.alert("No Metamask Installed");
      window.location.replace("https://metamask.io");
    }

    const handleAccountsChanged =(accounts)=>{
      if(provider.chainId === "0x38"){
        infoNotification(accounts[0]);
      }
      // Just to prevent reloading twice for the very first time
      if(JSON.parse(localStorage.getItem('activeAccount')) != null){
        setTimeout(()=>{window.location.reload()}, 3000);
      }
    };

    const handleChainChanged = (chainId)=>{
      if(chainId !== "0x38"){
        warningNotification();
      }
      window.location.reload();
    }

    const handleDisconnect = ()=>{};

    connectWallet();
    provider.on("accountsChanged", handleAccountsChanged);
    provider.on("chainChanged", handleChainChanged);
    provider.on("disconnect", handleDisconnect);
  },[]);

  const connectWallet = async () => {
    
    const web3modal = new Web3Modal();
    
    const connection = await web3modal.connect();
    
    let provider = new ethers.providers.Web3Provider(connection);
    
    const getNetwork = await provider.getNetwork();
    //11155111   97 56
    const ethereumChainId = 56;
    
    if(getNetwork.chainId !== ethereumChainId){
     
      warningNotification();
      try{
        await provider.provider.request({
          method: "wallet_switchEthereumChain",
          params:[{chainId:utils.hexValue(ethereumChainId)}]
        }).then(()=>window.location.reload());
      }catch(switchError){
        // This error code indicates that the chain has not been added to metamask
        // So will add Ethereum network to their metamask
        if(switchError.code === 4902){
          try{
            await provider.provider.request({
              method: "wallet_addEthereumChain",
              params:[
                {
                  chainId: utils.hexValue(ethereumChainId),
                  chainName: "Binance SmartChain Mainnet",
                  rpcUrls: ["https://rpc.ankr.com/bsc"],
                  blockExplorerUrls:["https://bscscan.com/"],
                  nativeCurrency:{
                    symbol:"BNB",
                    decimals:18
                  }
                }
              ]
            }).then(()=>window.location.reload());
          } catch(addError){
            throw addError;
          }
        }
      }
    }else{
     
      // It will execute if ethereum chain is connected
      // Here we will verify if user exists or not in our blockchain or else we will update user details in our contract as well as local storage
      const signer = provider.getSigner();
     
      const signerAddress = await signer.getAddress();
      
      const contract = new ethers.Contract(UserContractAddress, UserAbi.abi, signer);
      try{
        const getUserDetail = await contract.getUser(signerAddress);
        if(getUserDetail['profileImg']){
          // If user Exists
         
          window.localStorage.setItem("activeAccount", JSON.stringify(signerAddress));
          window.localStorage.setItem("userName", JSON.stringify(getUserDetail['name']));
          window.localStorage.setItem("userBio", JSON.stringify(getUserDetail['bio']));
         
          window.localStorage.setItem("userImage", JSON.stringify(getUserDetail['profileImg']));
          window.localStorage.setItem("userBanner", JSON.stringify(getUserDetail['profileBanner']));
        }else{
          // First Time user 
          // Get a Random avatar and update in the contract
          setLoadingState(true);
          let avatar = toonavatar.generate_avatar();
          let defaultBanner = "https://unsplash.com/photos/hXuNFlkjgqU";
          window.localStorage.setItem("activeAccount", JSON.stringify(signerAddress));
          window.localStorage.setItem("userName", JSON.stringify(''));
          window.localStorage.setItem("userBio", JSON.stringify(''));
          window.localStorage.setItem("userImage", JSON.stringify(avatar));
          window.localStorage.setItem("userBanner", JSON.stringify(defaultBanner));
          try{
            const transaction = await contract.updateUser('','',avatar, defaultBanner);
            await transaction.wait();
          }catch(error){
            notification({
              type:'warning',
              message: 'Get BNB',
              title:'Require Minimum 0.0001 BNB',
              position:'topR'
            });
            setLoadingState(false);
            return;
          }
        }
      }catch(e){
        notification({
          type:'warning',
          message: e.message,
          position:'topR'
        });
      }
      
      
      

      setProvider(provider);
      setIsAuthenticated(true);
    }
  }

  return (
    <>
    {isAuthenticated ?
      <div className='page'>
        <div className='sideBar'>
          <SideBar/>
        </div>
        <div className='mainWindow'>
          <Routes>
            <Route path='/' element={<Home/>}></Route>
            <Route path='/profile' element={<Profile/>}></Route>
            <Route path='/settings' element={<Settings/>}></Route>
            <Route path='/advertisement' element={<Advertisement/>}></Route>
            <Route path='/verifyAccount' element={<VerifyAccount/>}></Route>
            <Route path='/messages' element={<Message/>}></Route>
            <Route path='/profileUser/:account' element={<ProfileInDetail/>}></Route>
            <Route path='/messagesender/:account' element={<MessageSender/>}></Route>
            <Route path='/blabintag/:tagid' element={<BlabInTags/>}></Route>
          
          </Routes>
        </div>
        <div className='rightBar'>
          <RightBar/>
        </div>
      </div>
      : (
        <div className='loginPage'>
          <img src={blabLogo} alt='Blab Logo'  style={{width:'100px', height:'100px'}}/>
          {loading ?<Loading size={50} spinnerColor='green'/> : 
             <Button onClick={connectWallet} size='x1' text='Login With Metamask' theme='primary' icon={<Metamask/>}/>
          }
         
        </div>
      ) }
    </>
  );
}

export default App;
