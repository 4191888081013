import React, { useState, useEffect, useRef } from "react";
import "./BlabInFeed.css";
import { Avatar, Loading, useNotification } from "@web3uikit/core";
import { MessageCircle, Bin } from "@web3uikit/icons";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import {
  BlabContractAddress,
  FollowingContractAddress,
  UserContractAddress,
  Web3StorageApi,
} from "../config";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import IconButton from "@mui/material/IconButton";
import BlabAbi from "../abi/BlabBusiness.json";
import UserAbi from "../abi/UserBusiness.json";
import FollowAbi from "../abi/FollowingBusiness.json";
import blue from "../images/blue.png";
import gold from "../images/gold.png";
import { Web3Storage } from "web3.storage";
import Comment from "../pages/Comment";
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
function BlabInFeed(props) {
  const onlyUser = (props = props.profile);
  let reloadComponent = props.reload;
  const activeAccount = JSON.parse(localStorage.getItem("activeAccount"));
  const [blabs, setBlabs] = useState([]);
  const UserImage = JSON.parse(localStorage.getItem("userImage"));
  const [like, setLike] = useState(0);
  const [dislike, setDislike] = useState(0);
  const [accountType, setAccountType] = useState("");
  const [loadingState, setLoadingState] = useState("not-loaded");
  const [userAddress, setUserAddress] = useState(0);
  const inputFile = useRef(null);
  const [selectedImage, setSelectedImage] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const notification = useNotification();
  const [selectedItem, setSelectedItem] = useState(null);
  let ipfsUploadedUrl = " ";

  async function storeFile() {
    const client = new Web3Storage({ token: Web3StorageApi });
    const rootCid = await client.put(selectedFile);
    ipfsUploadedUrl = `https://${rootCid}.ipfs.dweb.link/${selectedFile[0].name}`;
  }

  const onImageClick = () => {
    inputFile.current.click();
  };

  const changeHandler = (event) => {
    const imgFile = event.target.files[0];
    setSelectedImage(URL.createObjectURL(imgFile));
    setSelectedFile(event.target.files);
  };

  useEffect(() => {
    if (onlyUser) {
      loadMyBlabs();
    } else {
      loadAllBlabs();
    }
    getUserDetail();
  }, [reloadComponent]);

  async function getUserDetail() {
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      UserContractAddress,
      UserAbi.abi,
      signer
    );
    const data = await contract.getUser(activeAccount);
  }

  async function loadMyBlabs() {
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      BlabContractAddress,
      BlabAbi.abi,
      signer
    );
    const data = await contract.getMyBlabs();
    const userName = JSON.parse(localStorage.getItem("userName"));
    const userImage = JSON.parse(localStorage.getItem("userImage"));

    const result = await Promise.all(
      data.map(async (blab) => {
        const unitTime = blab.timestamp;
        const date = new Date(unitTime * 1000);
        const blabDate = date.toLocaleDateString("fr-CH");
        const userContract = new ethers.Contract(
          UserContractAddress,
          UserAbi.abi,
          signer
        );
        const getAccountType = await userContract.getAccountType(blab.blaber);
        let item = {
          tweeter: blab.blaber,
          id: blab.id,
          blabText: blab.blabText,
          blabImg: blab.blabImg,
          isDeleted: blab.isDeleted,
          userName: userName,
          userImage: userImage,
          date: blabDate,
          like: parseInt(blab.like),
          dislike: parseInt(blab.dislike),
        };
        setAccountType(getAccountType);
        setUserAddress(blab.ber);
        return item;
      })
    );
    setBlabs(result.reverse());

    setLoadingState("loaded");
  }

  async function loadAllBlabs() {
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      BlabContractAddress,
      BlabAbi.abi,
      signer
    );
    const data = await contract.getAllBlab();

    const result = await Promise.all(
      data.map(async (blab) => {
        const unitTime = blab.timestamp;
        const date = new Date(unitTime * 1000);
        const blabDate = date.toLocaleDateString("fr-CH");
        const userContract = new ethers.Contract(
          UserContractAddress,
          UserAbi.abi,
          signer
        );
        const getUserDetail = await userContract.getUser(blab.blaber);
        const getAccountType = await userContract.getAccountType(blab.blaber);

        let item = {
          tweeter: blab.blaber,
          id: blab.id,
          blabText: blab.blabText,
          blabImg: blab.blabImg,
          isDeleted: blab.isDeleted,
          userName: getUserDetail["name"],
          userImage: getUserDetail["profileImg"],
          date: blabDate,
          like: parseInt(blab.like),
          dislike: parseInt(blab.dislike),
        };
        setAccountType(getAccountType);

        setUserAddress(blab.blaber);
        return item;
      })
    );

    setBlabs(result.reverse());
    setLoadingState("loaded");
  }

  async function deleteBlab(blabId) {
    setLoadingState("not-loaded");
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      BlabContractAddress,
      BlabAbi.abi,
      signer
    );
    const data = await contract.deleteBlab(blabId, true);
    await data.wait();
    notification({
      type: "success",
      title: "Blab deleted successfuly",
      position: "topR",
      icon: <Bin />,
    });
    loadMyBlabs();
  }

  async function likeBlab(blabId) {
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      BlabContractAddress,
      BlabAbi.abi,
      signer
    );
    
    try{
      const data = await contract.like(blabId, 2500);
      await data.wait();
   
      if (data === 0) {
        notification({
          type: "warnning",
          title: "You cannot like secondly",
          position: "topR",
        });
        loadMyBlabs();
      }else{
        notification({
          type: "success",
          title: "Blab Like successfuly",
          position: "topR",
        });
        setLike(like + 1);
        loadMyBlabs();
      }
     
    }catch(e){
     
      notification({
        type:"warning",
        title:"Are you hold MYRA?",
        position:"topR"
      })
      loadMyBlabs();
    }
  


    
    loadMyBlabs();
   
  }


  // async function loadAllAdvertisements(){
  //     const web3Modal = new Web3Modal();
  //     const connection = await web3Modal.connect();
  //     const provider = new ethers.providers.Web3Provider(connection);
  //     const signer = provider.getSigner();
  //     const contract = new ethers.Contract(AdvertisementContractAddress, Advertisement.abi,signer);
  //     const data = await contract.getAdvertisements();

  //     const result = await Promise.all(data.map(async advertisement=>{
  //         const unitTime = advertisement.timestamp;
  //         const date = new Date(unitTime * 1000);
  //         const blabDate = date.toLocaleDateString("fr-CH")
  //         let getUserDetail = await contract.getUser(advertisement.tweeter);

  //         let item ={
  //             advertisementer : advertisement.advertisementOwner,
  //             id: advertisement.advertisementId,
  //             tweetText: advertisement.advertisementText,
  //             tweetImg:advertisement.advertisementImg,
  //             isDeleted:advertisement.isDeleted,
  //             userName: getUserDetail['name'],
  //             userImage: getUserDetail['profileImg'],
  //             date:tweetDate,
  //             like: parseInt(advertisement.like),
  //             dislike: parseInt(advertisement.dislike)
  //         };

  //         return item;
  //     }));

  //     setBlabs(result.reverse());
  //     setLoadingState('loaded');
  // }

  async function dislikeBlab(blabId) {
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      BlabContractAddress,
      BlabAbi.abi,
      signer
    );
    try{
      const data = await contract.dislike(blabId,2500);
      await data.wait();
      if (data === 0) {
        notification({
          type: "warnning",
          title: "You cannot dislike secondly",
          position: "topR",
        });
        loadMyBlabs();
   
      }else{
        notification({
          type: "success",
          title: "Blab Dislike successfuly",
          position: "topR",
        });
        loadMyBlabs();
        setDislike(dislike + 1);
      }
      
    }catch(e){
      notification({
        type: "warning",
        title: "Do you hold MYRA?",
        position: "topR",
      });
      loadMyBlabs();
   
    }
    
    loadMyBlabs();
   
  }

  async function follow(userAddress1) {
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      FollowingContractAddress,
      FollowAbi.abi,
      signer
    );
    try{
      const data = await contract.follow(userAddress1);
      await data.wait();
    }catch(e){

    }
    
  }


    

  const handleItemClick = (blab) => {
    setSelectedItem(blab);
  };

  const handleToClose = () => {
    setSelectedItem(null);
  };

  if (loadingState === "not-loaded") {
    return (
      <div className="loading">
        <Loading size={60} spinnerColor="#8247e5" />
      </div>
    );
  }

  if (loadingState === "loaded" && !blabs.length) {
    return <h1 className="loading">No Blab Available</h1>;
  }

  

  return (
    <>
      {blabs.map((blab) => (
        <div className="feedTweet" key={blab.id}>
          <Avatar isRounded image={blab.userImage} theme="image" size={60} />
          <div className="completeTweet">
            <div className="who">
              {blab.userName}

              {accountType.match("Blue_Type") ? (
                <img className="img-verify" src={blue} alt="Account_Type" />
              ) : (
                <div></div>
              )}
              {accountType.match("Golden_Type") ? (
                <img className="img-verify" src={gold} alt="Account_Type" />
              ) : (
                <div></div>
              )}
              <div className="accWhen"><a className="accWhenLink" href={"profileUser/"+blab.tweeter}>{blab.tweeter}</a></div>
              <div className="accWhen">{blab.date}</div>
              <div>
                {blab.tweeter.match(activeAccount) ? (
                  <div className=""></div>
                ) : (
                  // <div className="follow" onClick={() => follow(blab.tweeter)}>
                  //   Follow
                  // </div>
                  <div></div>
                )}
              </div>
            </div>
            <div className="blabContent">
              {blab.blabText}
              {blab.blabImg !== "" && (
                <img
                  src={blab.blabImg}
                  className="blabImg"
                  alt="Type Of Blab"
                />
              )}
            </div>
            <div className="interactions">
              <div className="interactionNums">
              <div onClick={()=> handleItemClick(blab)}>
                  <MessageCircle fontSize={20} />
                  {selectedItem && selectedItem.id === blab.id &&(
                    <Dialog className="dialog" open={true} onClose={handleToClose}>
                        <DialogTitle>{blab.text}</DialogTitle>
                        <DialogContent><Comment blab={blab.id}/></DialogContent>
                      
                    </Dialog>
                )}
                </div>
               
              </div>

              

              {/* <div className='interactionNums'>
                        <Calendar fontSize={20}/>{blab.date}
                    </div> */}

              {
                onlyUser ? (
                  <div className="interactionNums">
                    <Bin
                      fontSize={20}
                      color="#FF0000"
                      onClick={() => deleteBlab(blab.id)}
                    />
                  </div>
                ) : (
                  <div className="likeOrDislike">
                    <IconButton
                      className="likebutton"
                      onClick={() => likeBlab(blab.id)}
                    >
                      <ThumbUpIcon></ThumbUpIcon>
                      <p className="likeBlab">{blab.like}</p>
                    </IconButton>
                    <IconButton onClick={() => dislikeBlab(blab.id)}>
                      <ThumbDownIcon></ThumbDownIcon>
                      <p className="dislikeBlab">{blab.dislike}</p>
                    </IconButton>
                  </div>
                )

                //<Matic fontSize={20}/>
                /*
                        <div className=''>
                    
                        <div className='interactionNums'><ThumbDownIcon/>1</div>
                        </div>*/
              }
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default BlabInFeed;
